.login {
	background: #F1F2F8;
	min-height:  100vh;

	.header {
		position: absolute;
		display: fixed;
		width: 100%;
		height: 290px;
		z-index: 1;
		left: 0px;
		top: 0px;
		background: #E50019;
	}

	.body {
		z-index: 99;
		margin-top: 50px;
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.logo {
			z-index: 99;
			margin-bottom: 20px;
			background-color: #E50019;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
	
			.title {
				color: #FFFFFF;
			}
		}
		.content {
			z-index: 99;

			background: #FFFFFF;
			width: 780px;
			border-radius: 8px;
			padding: 30px;
		}
	}
}