html,
body {
	overscroll-behavior-x: none;
	overscroll-behavior-y: none;
}

body {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ant-layout-content {
	background: #ffffff;
}

.container {
	padding: 0 50px;

	&--dashboard {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.ant-btn-primary {
	border-color: #E50019 !important;
	background: #E50019 !important;
}
.ant-btn-primary:hover {
	border-color: #E50019 !important;
	background: #E50019 !important;
}
.ant-btn-primary:focus {
	border-color: #E50019 !important;
	background: #E50019 !important;
}

// .table-layout {
// 	.ant-layout-content {
// 		position: absolute;
// 		top: 88px;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		z-index: 2;
// 	}
// }

.margin-right-10 {
	margin-right: 10px;
}
.ant-layout-sider-children {
	background-color: #E50019;
}

.mt10 {
	margin-top: 10px;
}

.ml10 {
	margin-left: 10px;
}

.mr10 {
	margin-right: 10px;
}

.mb10 {
	margin-bottom: 10px;
}