.profile {
	display: flex;
	flex-direction: column;
	padding-top: 50px;
	overflow: auto;
	background: #ffffff;
	align-items: center;
	width: 100%;
	height: 100vh;

	.content {
		width: 100%;
		padding: 30px;
	}

	.avatar {
		margin-bottom: 10px;
	}
	.change_avatar {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.info_line {
		display: flex;
		margin-top: 20px;

		.title {
			font-weight: 600;
			margin-right: 10px;
		}
	}
	.change_password {
		display: flex;
		flex-direction: column;
		padding-top: 50px;
		align-items: center;	
	}
}

.ml-20 {
	margin-left: 20px;
}
