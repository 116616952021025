.score-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.score-btn-end {
    display: block;
}
.score-content {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid black;
    padding-top: 16px;
    padding-bottom: 11px;
}
.score-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    align-content: center;
    text-align: center;
    background-color: #F4F4F4;
    margin-bottom: 5px;
    margin-left: 16px;
    padding: 10px;
    width: 200px;

    .subject {
        color: #2D2D2D;
        font-weight: 700;
        font-size: 14px;
        margin-top: auto;
        margin-bottom: auto
    }
}
.brand-detail {
    border-radius: 5px;
    align-content: center;
    text-align: center;
    background-color: #E50019;
    margin-bottom: 5px;
    margin-right: 20px;
    width: 136px;
    padding: 21px;
    padding-top: 20px;
    .brand-content-detail {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 14px;
        height: 36px;
        text-align: center
      };
      .brand-content-score {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        margin-top: 10px
      };
}

.flex {
    display: flex;
}
.dailyreport-action {
    margin-left: 10px;
    margin-top: 5px;
}

.dailyreport-detail {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 90%;
    .lesson {
        padding: 10px;
        color: #2D2D2D;
        font-size: 16px;
        font-weight: 700;
    }
    .report {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #c0bebe;
        .report-head {
           width: 70%;
           .report-subject {
            padding-bottom: 10px;
            color: #0072BC;
            font-weight: 700;
           }
           .report-short {
            padding-bottom: 10px;
            font-weight: 700;
           }
           .report-desc {
            padding-bottom: 10px;
           }
        }
        .report-score {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            img {
                margin-left: 10px;
            }
        }
    }
    .report:last-child{
        border-bottom: none;
    }
}