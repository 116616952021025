.post {
  .body {
    .add-new {
      display: flex;
      justify-content: right;
      margin-bottom: 30px;

      &__upload {
        margin-right: 20px;
      }
    }
  }

  .action_button {
    display: flex;
    float: right;
    
    button {
      margin: 30px;
      background-color: #302E90;
    }
  }

  .preview-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .preview {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-top: 50px;
    background-color: #FFFFFF;
    width: 350px;
    height: 600px;
    overflow: auto;

    h3 {
      font-weight: 600;
    }

    img {
      width: 300px;
      height: 150px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items:flex-start;
      width: 80%;
      margin-top: 20px;
    }
  }
}