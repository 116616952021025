.exam,
.student-exam {
  &-btn {
    &-actions {
      margin-bottom: 10px;
    }
  }

  &-text {
    &-success {
      color: green;
    }
  }

  &-text {
    &-danger {
      color: red;
    }
  }

  &-question {
    &-create {
      &-card {
        margin: 0.5em;
      }
    }
  }

  &-detail {
    &-readonly {
      .ant {
        &-input,
        &-input-number,
        &-picker,
        &-select,
        &-checkbox-wrapper,
        &-checkbox {
          &:hover .ant-checkbox-inner {
            border-color: #d9d9d9 !important;
          }

          &-disabled,
          & + span {
            background-color: white !important;
            color: rgba(0, 0, 0, 0.85) !important;
            cursor: default !important;

            .ant-select-selector {
              background-color: white !important;
              color: rgba(0, 0, 0, 0.85) !important;
              cursor: default !important;
            }

            .ant-picker-suffix,
            .ant-select-arrow {
              display: none;
            }

            .ant-input-number-input {
              background-color: white !important;
              color: rgba(0, 0, 0, 0.85) !important;
              cursor: default !important;
            }
          }
          & input[disabled] {
            background-color: white !important;
            color: rgba(0, 0, 0, 0.85) !important;
            cursor: default !important;
          }
        }
      }
    }
  }
}
