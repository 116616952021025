.tutorial {
  .body {
    .add-new {
      display: flex;
      justify-content: right;
      margin-bottom: 30px;
    }

    
  }

  .action_button {
    display: flex;
    float: right;
    
    button {
      margin: 30px;
      background-color: #302E90;
    }
  }

  .hashtag-parent {
    display: flex;
  }

  .hashtag-item {
    color: #2C275F;
    margin-right: 5px;
  }

  .preview {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 50px;

    h3 {
      font-weight: 600;
    }

    img {
      width: 80%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items:flex-start;
      width: 80%;
      margin-top: 10px;
    }
  }
}

.addSmartQuestionContainer {
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
}
.addSmartQuestion {
  margin-bottom: 10px;
}