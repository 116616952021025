.avatar_form {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    margin-top: 5px;
  }
}